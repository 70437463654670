/**
 * @generated SignedSource<<b2d616e5c14ba6383f2f93773584768b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoyaltyProgramLevelImages_image$data = {
  readonly badge: {
    readonly height: number;
    readonly url: string;
    readonly width: number;
  };
  readonly description: string;
  readonly title: string;
  readonly " $fragmentType": "LoyaltyProgramLevelImages_image";
};
export type LoyaltyProgramLevelImages_image$key = {
  readonly " $data"?: LoyaltyProgramLevelImages_image$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoyaltyProgramLevelImages_image">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoyaltyProgramLevelImages_image",
  "selections": [
    {
      "alias": "badge",
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "width",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "height",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "LoyaltyProgramLevel",
  "abstractKey": null
};

(node as any).hash = "a8e1f31c22bae0d00a77c070abeddf12";

export default node;
